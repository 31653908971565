import React, { useCallback } from 'react';
import { useState } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { useForm } from 'react-hook-form';
import { MAIN_BUTTON_CLASSNAME, PHONE_INPUT } from '../../constants';
import Button from '../Button';
import Heading from '../Heading';
import ContactInput from './components/forms/ContactInput';
import Remark from './components/Remark';

function ContactUsModal({ handleClick, dealsContactModal, overrideCalendlyURL }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    (data) => {
      setIsLoading(true);
      localStorage.setItem('name', data?.name);
      localStorage.setItem('email', data?.email);
      localStorage.setItem('phone', data?.phone);
      setIsModalOpen(true)
      if(!dealsContactModal) {
        setUserData(data)
      }
      else {
        handleClick(data)
      }
    },
    [errors, isLoading, dealsContactModal]
  );

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      let eventUuid = e.data.payload.event.uri.split('/');
      eventUuid = eventUuid[eventUuid.length - 1];

      handleClick(userData, eventUuid);
    },
  });

  return (
    <div className="contact-us-modal">
      <Heading
        title={!dealsContactModal ? <>Speak to a mortgage expert</> : "You’re One Step Away"}
        subtitle={
          <>
            Enter your email address and phone number.
            <br />
            We’ll only use this to contact you about remortgaging.
          </>
        }
        showOnMobile
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="contact-us-modal__form">
          <ContactInput
            title="Name"
            placeholder="John Doe"
            name="name"
            register={register}
            validationOptions={{
              required: true,
            }}
            errors={errors}
          />
          <ContactInput
            title="Email Address"
            placeholder="johndoe.01@gmail.com"
            name="email"
            register={register}
            validationOptions={{
              required: true,
              pattern: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            }}
            errors={errors}
          />
          <ContactInput
            title="Phone no."
            placeholder="7002131923"
            name="phone"
            type={PHONE_INPUT}
            register={register}
            validationOptions={{
              required: true,
              pattern: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g,
            }}
            errors={errors}
          />
        </div>
          <div className="contact-us-modal__button-container">
            <Button buttonText={!dealsContactModal ? "Submit" : "View Deals"} height="56px" submit loading={isLoading && dealsContactModal} />
          </div>
          {!dealsContactModal && (
              <PopupModal
                url={overrideCalendlyURL ? overrideCalendlyURL : "https://calendly.com/teamsprive/chat-to-our-expert-web"}
                rootElement={document.getElementById('___gatsby')}
                text="Book an appoinment"
                className={`${MAIN_BUTTON_CLASSNAME} button__size-modal`}
                open={isModalOpen}
                onModalClose={() => setIsModalOpen(false)}
                prefill={{
                  name: userData?.name,
                  email: userData?.email,
                  location: "44" + userData?.phone,
                  customAnswers: {
                    a1: "44" + userData?.phone
                  }
                }}
              />
          )}
      </form>
      <Remark dealsContactModal={dealsContactModal}/>
    </div>
  );
}

export default ContactUsModal;
