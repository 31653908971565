import React from 'react';

function Remark({dealsContactModal}) {
  return (
    <>
      <p className="remark">
        By clicking on '{dealsContactModal ? "View Deals" : "Submit"}', you're accepting our updated
        <a href="/remortgage-terms" target="_blank">
          Terms & Conditions
        </a>
        and
        <a href="/privacy" target="_blank">
          Privacy Policy
        </a>
      </p>
    </>
  );
}

export default Remark;
