import React, { useMemo } from 'react';
import { PHONE_INPUT } from '../../../../constants';
import britainFlag from '../../../../assets/images/icons/other/britain-flag.png';

function ContactInput({ title, placeholder, name, type, register, validationOptions, errors }) {
  const isPhoneInput = useMemo(() => type === PHONE_INPUT, [type]);

  return (
    <div className="contact-input__container">
      <p>{title}</p>
      <div className={isPhoneInput ? 'contact-input__wrapper' : ''}>
        {isPhoneInput && (
          <div className="contact-input__code-container">
            <img src={britainFlag} />
            <p>+44</p>
          </div>
        )}
        <input
          placeholder={placeholder}
          className="contact-input"
          type={isPhoneInput ? 'number' : 'text'}
          inputMode={isPhoneInput ? "numeric" : ""}
          style={{
            borderColor: errors[name] ? '#da2972' : null,
          }}
          {...register(name, validationOptions)}
        />
      </div>
    </div>
  );
}

export default ContactInput;
